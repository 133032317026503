<template>
  <div class="box">
    <img class="img" src="../../assets/shouye2_banner333.png" alt="" />
    <div class="mask"></div>
    <div class="content">
      <img src="../../assets/denglu_imgs@3x.png" class="content_img" alt="" />
      <div class="rigth">
        <img class="logo" src="../../assets/logo.png" alt="" />
        <div class="tab">
          <div class="item active">绑定手机号</div>
        </div>
        <div class="phone">
          <img src="../../assets/shouji_icon.png" alt="" />
          <input v-model="form.phone" type="text" placeholder="请输入手机号" />
        </div>
        <div class="password">
          <img src="../../assets/mima_icon.png" alt="" />
          <input v-model="form.verify" type="text" placeholder="请输入验证码" />
          <div class="verification">
            <span v-if="time < 0" @click="getCode">发送验证码</span>
            <span v-if="time >= 0">{{ time }} s</span>
          </div>
        </div>
        <div class="btn" @click="register">绑 定</div>
        <div class="tac">
          已有账号？<span
            @click="gologin"
            style="cursor: pointer; color: #1276fb"
            >立即登录</span
          >
          <span @click="goforgetthepassword" style="cursor: pointer"
            >&nbsp;&nbsp;&nbsp;忘记密码</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      form: {
        username: "",
        password: "",
        phone: "",
        verify: "",
      },
      time: -1,
      code: "",
    };
  },
  computed: {
    ...mapState({
      wx: (state) => state.wx,
    }),
  },
  methods: {
    ...mapActions(["getVerify", "getBound", "getsmsVerificationr"]),
    goforgetthepassword() {
      this.$router.push({
        path: "/forgetthepassword",
      });
    },
    register() {
      // if (this.form.verify == "") {
      //   this.$message({
      //     message: "请获取验证码",
      //     type: "cancel",
      //   });
      //   return false;
      // }
      // if (this.form.verify != this.code) {
      //   this.$message({
      //     message: "验证码不正确",
      //     type: "cancel",
      //   });

      //   return false;
      // }
      this.getBound({
        username: this.form.phone,
        verify: this.form.verify,
        openid: this.wx.openid,
        unionid: this.wx.unionid,
        headpic: this.wx.headimgurl,
        realname: this.wx.nickname,
      }).then((res) => {
        this.$message({
          message: res.msg,
          type: res.code == 200 ? "success" : "cancel",
        });
        if (res.code == 200) {
          this.getsmsVerificationr({
            id: res.data.id,
            verify: "88888",
            phone: res.data.phone,
            username: res.data.username,
          });
          this.$router.go(-1);
        }
      });
    },
    getTime() {
      var tiemr = setInterval(() => {
        if (this.time >= 0) {
          this.time--;
        } else {
          clearInterval(tiemr);
        }
      }, 1000);
    },
    //获取验证码
    getCode() {
      if (!/^1[3456789]\d{9}$/.test(this.form.phone)) {
        this.$message({
          type: "cancel",
          message: "请输入正确的手机号",
        });
        return;
      }
      this.form.verify = "";
      this.getVerify({
        kindof: 1,
        phone: this.form.phone,
      }).then((res) => {
        this.$message({
          type: res.code == 200 ? "success" : "cancel",
          message: res.msg,
        });
        if (res.code == 200) {
          this.code = res.data;
          this.time = 60;
          this.getTime();
        }
      });
    },
    introduce() {
      this.$router.push("/wxlogin");
    },
    gologin() {
      this.$router.push("/login");
    },
  },
};
</script>

<style lang="scss" scoped>
input:focus {
  outline: none;
  border: 0 none;
}

.box {
  overflow-x: hidden;
  overflow-y: hidden;
  height: 100vh;
  position: relative;
  .img {
    width: 1922px;
    height: 531px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
  }
  .mask {
    width: 1922px;
    height: 531px;
    background: rgba($color: #000000, $alpha: 0.3);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1001;
  }
  .content {
    display: flex;
    width: 786px;
    height: 448px;
    background: #ffffff;
    box-shadow: 0px 5px 20px 0px #06316a;
    border-radius: 10px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1002;
    .content_img {
      width: 318px;
      height: 449px;
      border-radius: 10px 0px 0px 10px;
      vertical-align: middle;
    }
    .rigth {
      flex: 1;
      padding: 36px 64px;
      box-sizing: border-box;
      .logo {
        width: 143px;
        height: 48px;
      }
      .tab {
        margin-top: 42px;
        display: flex;
        justify-content: center;

        .item {
          cursor: pointer;
          font-size: 18px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #444444;
        }
        .active {
          font-size: 18px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #1276fb;
        }
      }
      .phone {
        margin-top: 41px;
        padding-bottom: 5px;
        border-bottom: 1px solid #d4d9e2;
        input {
          &::placeholder {
            font-size: 16px;
          }
          margin-left: 13px;
          transform: translateY(-3px);
          font-size: 20px;
          border: 0 none;
          height: 30px;
        }
      }
      .password {
        margin-top: 30px;
        padding-bottom: 5px;
        border-bottom: 1px solid #d4d9e2;
        input {
          &::placeholder {
            font-size: 16px;
          }
          width: 100px;
          margin-left: 13px;
          transform: translateY(-3px);
          font-size: 20px;
          border: 0 none;
          height: 30px;
        }
        .verification {
          cursor: pointer;
          transform: translateY(-3px);
          float: right;
          text-align: center;
          line-height: 30px;
          width: 105px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          height: 30px;
          background: #1276fb;
          border-radius: 15px;
        }
      }
      .btn {
        cursor: pointer;
        margin-top: 37px;
        text-align: center;
        line-height: 47px;
        height: 47px;
        background: #1276fb;
        border-radius: 24px;
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
      }
      .tac {
        margin-top: 8px;
        text-align: center;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #9c9c9c;
      }
    }
  }
}
</style>
